.reset-password-popup-container {
  display: flex;
  justify-content: center;
  position: absolute;
  text-align: center;
  align-items: center;
  /* z-index: 9999999; */
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(146, 148, 151, 0.6);
}

.reset-password-popup {
  height: 450px;
  width: 450px;
  background-color: #ffffff;
  color: rgb(0, 120, 245);
  border-radius: 3px;
  padding: 45px;
  top: 50%;
}

.reset-password-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  padding-top: 50px;
  height: 100%;
  width: 100%;
}

.reset-password-header {
  font-weight: 600;
  font-size: 20pt;
}

.confirm-email-button {
  color: #ffffff;
  border: none;
  background-color: rgb(0, 120, 245);
  border-radius: 5px;
  height: 50px;
  width: 80%;
  cursor: pointer;
}

.show-confirm {
  color: #000;
  font-size: 12pt;
  margin-top: 10px;
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.hide {
  display: none;
}

.reset-password-form {
  background-color: #ffffff;
  flex-direction: column;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  padding-top: 0px;
  border-radius: 3px;
  width: 100%;
}

.reset-password-form-input-field {
  width: 100%;
}

.reset-password-label {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  color: rgb(120, 121, 125);
  margin-bottom: 5px;
  margin-left: 2px;
}

.reset-password-form-input-box {
  width: 100%;
  height: 45px;
  font-size: 14pt;
  border-radius: 5px;
  border-top: 1px solid rgb(214, 216, 216);
  border-bottom: 1px solid rgb(214, 216, 216);
  border-left: 1px solid rgb(214, 216, 216);
  border-right: 1px solid rgb(214, 216, 216);
}

.reset-password-button {
  margin-top: 25px;
  color: #ffffff;
  background-color: rgb(0, 120, 245);
  width: 100%;
  border: none;
  height: 50px;
  border-radius: 5px;
  font-size: 12pt;
  font-weight: bold;
  cursor: pointer;
}

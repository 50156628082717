.profile-page {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-left: 10px;
}

.account-info-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.top-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.edit-user-details-tile {
  height: max-content;
  min-height: 275px;
  background-color: #ffffff;
  margin: 10px;
  border-radius: 9px;
}

.edit-user-header {
  background-color: rgb(0, 120, 245);
  display: flex;
  justify-content: flex-start;
  color: #ffffff;
  font-weight: bold;
  align-items: center;
  border-radius: 7px 7px 0px 0px;
  height: 40px;
  margin-bottom: 25px;
}

.edit-user-header-title {
  padding-left: 10px;
}

.edit-user-body {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.edit-user-form {
  width: 60%;
  margin-top: 15px;
  margin-right: 10px;
  padding: 10px;
}

.form-fields {
  width: 100%;
}

.update-form-input {
  width: 100%;
  height: 25px;
  margin-bottom: 15px;
  color: rgb(108, 118, 125);
}

.update-form-input input {
  border-radius: 0px;
  border: 1px solid rgb(146, 148, 151);
}

.form-input-text {
  margin-bottom: 10px;
}

.update-info-form-btn {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 45px;
  width: 100px;
  border-radius: 9px;
  background-color: rgb(0, 120, 245);
  cursor: pointer;
}

.update-info-form-btn:active {
  background-color: rgb(14, 92, 178);
}

.sms-form-btn {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 45px;
  width: 100px;
  border-radius: 9px;
  background-color: rgb(255, 255, 255);
  border-color: rgb(14, 92, 178);
  color: rgb(14, 92, 178);
  cursor: pointer;
  margin-right: 10px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.btn-text {
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
}

.change-password-tile {
  background-color: rgb(14, 56, 133);
  height: 275px;
  margin: 10px;
  border-radius: 9px;
  cursor: pointer;
}

.help-tile {
  background-color: rgb(250, 187, 5);
  height: 275px;
  margin: 10px;
  border-radius: 9px;
  cursor: pointer;
}

.profile-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 110px;
  margin-bottom: 20px;
  color: rgb(146, 148, 151);
}

.avatar-footer {
  color: #0078f5;
  margin-top: 2px;
  font-size: 14.5px;
  font-weight: 500;
  cursor: pointer;
}

.avatar-footer-label {
  text-align: center;
  display: block;
  box-sizing: border-box;
  line-height: 1.5;
  padding: 8px 6px;
  width: 64px;
  height: 36px;
  border-radius: 4px;
  transition: background-color 250ms;
}

.avatar-footer-label:hover {
  background-color: rgb(245, 245, 245);
}

.change-password-header {
  color: #ffffff;
  font-weight: bold;
  padding-top: 15px;
}

.change-password-body {
  height: 305px;
}

.change-password-logo {
  font-size: 100px;
  color: #ffffff;
  padding-top: 55px;
}

.help-tile-header {
  color: #ffffff;
  font-weight: bold;
  padding-top: 15px;
}

.help-tile-logo {
  font-size: 100px;
  color: #ffffff;
  padding-top: 55px;
}

.middle-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.add-liquid-account {
  height: 225px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(30, 197, 119);
}

.add-alternative-account {
  height: 225px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(0, 120, 245);
}

.add-personal-account {
  height: 225px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(0, 165, 163);
}

.add-expense-account {
  height: 225px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 9px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(249, 79, 89);
}

.add-accounts-header {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ffffff;
}

.add-account-title {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}

.add-accounts-body {
  display: flex;
  justify-content: center;
  text-align: center;
}

.add-statements {
  background-color: rgb(26, 136, 103);
}

.liq-button-background {
  border-radius: 50%;
  margin-top: 45px;
  padding-top: 2px;
  padding-left: 2px;
  width: 80px;
  height: 80px;
  background-color: rgb(30, 197, 119);
  border: 3px solid rgb(5, 166, 96);
}

.alt-button-background {
  border-radius: 50%;
  margin-top: 45px;
  padding-top: 2px;
  padding-left: 2px;
  width: 80px;
  height: 80px;
  background-color: rgb(0, 120, 245);
  border: 3px solid rgb(21, 101, 187);
}

.per-button-background {
  border-radius: 50%;
  margin-top: 45px;
  padding-top: 2px;
  padding-left: 2px;
  width: 80px;
  height: 80px;
  background-color: rgb(0, 165, 163);
  border: 3px solid rgb(0, 143, 136);
}

.exp-button-background {
  border-radius: 50%;
  margin-top: 45px;
  padding-top: 2px;
  padding-left: 2px;
  width: 80px;
  height: 80px;
  background-color: rgb(249, 79, 89);
  border: 3px solid rgb(206, 41, 61);
}

.connected-accounts-banner-container {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  height: 100px;
  width: 89vw;
}

.banner-text {
  color: rgb(97, 95, 95);
  margin-top: 40px;
  font-size: 18px;
  font-weight: bold;
}

.pseudo-container {
  width: 85%;
  height: 50px;
  border-bottom: 1px solid rgb(97, 95, 95);
}

.bottom-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.liq-styles {
  background-color: rgb(255, 255, 255);
  color: rgb(25, 155, 90);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  font-size: 65px;
  cursor: pointer;
}

.alt-styles {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 120, 245);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  font-size: 65px;
  cursor: pointer;
}

.per-styles {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 165, 163);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  font-size: 65px;
  cursor: pointer;
}

.icon-only {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
}

.icon-only-icon {
  font-size: 3em;
  color: #ffffff;
  margin-top: 55px;
}

.exp-styles {
  background-color: rgb(255, 255, 255);
  color: rgb(249, 79, 89);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  font-size: 65px;
  cursor: pointer;
}

.linked-accounts-popup {
  display: flex;
  justify-content: center;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(146, 148, 151, 0.5);
}

#files {
  visibility: hidden;
  width: 0px;
}

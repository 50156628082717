.header {
  margin-top: 20px;
  font-weight: medium;
}

@media screen and (min-width: 601px) {
  @media screen and (max-width: 1280px) {
    .networth-info-container {
      margin-top: 20px;
    }
  }
}

.header::after {
  content: '';
  display: block;
  margin: 0 auto;
  width: 20%;
  border-bottom: 1.5px solid rgb(39, 98, 91);
}

.net-worth-totals-container::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 20%;
  border-bottom: 1.5px solid rgb(39, 98, 91);
}

@media screen and (min-width: 996px) {
  .header {
    font-size: 18px;
  }
}

@media screen and (max-width: 995px) {
  .header {
    font-size: 18px;
  }

  @media screen and (max-width: 520px) {
    .header {
      font-size: 15px;
    }
  }
}

.avatar {
  margin-top: 20px;
  color: gray;
  background-color: lightgray;
  border-radius: 100px;
}

@media screen and (min-width: 996px) {
  .avatar {
    font-size: 85px;
  }
}

@media screen and (max-width: 995px) {
  .avatar {
    font-size: 65px;
  }

  @media screen and (max-width: 600px) {
    .avatar {
      font-size: 45px;
    }
  }
}

.pseudo-ele {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 50px;
  border-bottom: 1.5px solid rgb(39, 98, 91);
}

.net-worth-totals-container {
  font-size: 16px;
  font-weight: 250;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.net-worth-totals {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.asset-liability-total {
  color: rgb(36, 99, 92);
  font-weight: normal;
}

.net-worth-title {
  font-size: 16px;
  font-weight: 250;
  border-radius: 8px;
}

@media screen and (max-width: 995px) {
  .net-worth-title {
    margin-top: -8px;
  }
  @media screen and (max-width: 536px) {
    .net-worth-title {
      margin-top: -15px;
    }
  }
}

.total-net-worth {
  margin-top: -10px;
  color: rgb(39, 98, 91);
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 996px) {
  .total-net-worth {
    font-size: 45px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 995px) {
  .total-net-worth {
    font-size: 35px;
  }
}

.assets-and-liabilities {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.asset-liabilities-labels {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.asset-liabilities-values {
  display: flex;
  flex-direction: column;
  text-align: start;
}

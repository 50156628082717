.manual-modal-container {
  background: rgba(146, 148, 151, 0.6);
  padding: 15px;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;
  align-self: center;
  align-content: center;
  margin: 0px;
}

.modal-header {
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 25px;
  width: 100%;
}

.modal-header-close {
  font-size: 35px;
  padding-bottom: 15px;
}

.modal-header-close svg {
  font-size: 35px;
}

.modal-header-left {
  width: fit-content;
}

.modal-header-right {
  width: fit-content;
}

.manual-tx-inner-header {
  margin-bottom: 10px;
}

.modal-tx-name {
  width: 100%;
}

.modal-tx-input {
  border-radius: 20px;
  padding: 10px;
  background-color: rgb(229, 230, 231);
  color: rgb(117, 118, 120);
  border: none;
  outline: none;
  height: 20px;
  width: 200px;
}

.modal-tx-form {
  margin-top: 20px;
  /* height: 600px; */
  width: 500px;
}

.modal-tx-form form {
  height: 150px;
}

.manual-form-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  height: 100%;
}

.add-tx-btn {
  background-color: rgb(0, 120, 245);
  color: #fff;
  border-radius: 20px;
  width: 200px;
  height: 25px;
  padding: 4px;
  text-align: center;
  align-self: flex-end;
  cursor: pointer;
  outline: none;
  border: none;
}

.manual-category {
  display: flex;
  flex-direction: row;
}

.manual-form-label-and-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.manual-tx-label {
  font-size: 8pt;
}

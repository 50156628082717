.asset-detail-tile-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  border-radius: 9px;
  cursor: pointer;
  overflow: scroll;
}

.data-table {
  min-width: 600px;
  font-family: 'Roboto';
}

.asset-holdings-container .data-table .columns {
  padding-top: 3px;
  padding-bottom: 3px;
  width: 80px;
}

.asset-tables {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.asset-detail-tile-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: fit-content;
  margin-bottom: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.asset-detail-tile-header-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.asset-detail-header-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  height: 100%;
  width: 60%;
}

.detailed-view-logo {
  max-width: 285px;
  max-height: 110px !important;
  height: inherit !important;
  margin-right: 10px;
}

.real-estate-header {
  display: flex;
  flex-direction: row;
  /* text-align: left; */
  /* width: 100%; */
}

.detailed-ending {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 15px;
  font-size: 8pt;
  color: rgb(106, 105, 107);
}

.detailed-acct-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 500;
}

.detailed-view-acct {
  width: fit-content;
  word-break: keep-all;
}

hr {
  width: 20%;
  height: 1px;
  margin: auto;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-color: #000;
}

.asset-detail-header-right {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.investment-details-view {
  font-size: 9pt;
  margin-top: 5px;
}

.asset-detail-tile-header-account-name {
  font-size: 16px;
  font-weight: bold;
}

.asset-detail-tile-header-account-number {
  font-size: 12px;
}

.asset-detail-tile-header-account-value {
  /*font-size:18px;*/
}
.dialog-change-in-value {
  font-size: 16px;
}

.asset-account-value {
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 18px;
}

.manual-account-value:hover {
  border-radius: 9;
  color: grey;
  width: 45%;
}

.real-estate-details {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 150%;
}

.real-estate-left {
  margin-right: 20px;
}

.asset-detail-tile-header-trending-tag {
  font-size: 10px;
  color: #ffffff;
  border-radius: 20px;
  padding: 4px;
  width: fit-content;
  height: fit-content;
}

.asset-detail-tile-header-close-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 10px;
  font-size: 30px;
  color: rgb(166, 168, 171);
}

.asset-detail-tile-close {
  z-index: 1;
}

.asset-holdings-container {
  border: 1px solid rgb(166, 168, 171);
  border-radius: 10px;
  padding: 10px 10px 15px 10px;
  margin-bottom: 10px;
  /* min-height: 200px; */
  max-height: 325px;
  overflow-y: scroll;
}

.asset-holdings-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 1px;
  padding: 10px;
  padding-top: 5px;
}

.asset-holdings-header-left {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 275px;
}

.asset-holdings-header-right {
  margin-right: 35px;
}

.holdings-title {
  justify-content: left;
  font-size: 16px;
  font-weight: bold;
}

.holdings-search {
  text-align: left;
  border-radius: 20px;
  margin-right: 0;
  font-size: 12px;
  width: 300px;
  padding: 3px 0px 2px 15px;
  color: rgb(134, 141, 147);
  background-color: rgb(229, 230, 231);
}

.asset-transactions-container {
  border: 1px solid rgb(166, 168, 171);
  border-radius: 10px;
  padding: 10px 10px 30px 10px;
  margin-bottom: 10px;
  min-height: 200px;
  overflow: scroll;
}

.asset-charts-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.asset-details-donut-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  height: 245px;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  color: #ffffff;
}

.asset-details-donut {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.asset-details-donut-inner-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 125px;
  height: 125px;
  align-items: center;
}

.inner-wrapper {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 200px;
}

h5 {
  margin-top: 5px;
  margin-bottom: 2px;
}

.asset-details-donut-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  text-align: start;
  margin-bottom: 25px;
}

.asset-details-donut-header-title {
  font-size: 16px;
  font-weight: medium;
  border-bottom: 1px solid #ffffff;
}

.asset-details-donut-header-two {
  font-size: 14px;
}

.asset-details-donut-header-left {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 150px;
}

.line-chart-container {
  margin-right: 5px;
  height: 245px;
  border-radius: 10px;
  border: 1px solid rgb(166, 168, 171);
}

.line-chart-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 60px;
  padding: 10px 10px 0px 10px;
  border-bottom: 1px solid rgb(175, 177, 179);
}

.card .chartjs-render-monitor {
  max-height: 175px;
  min-height: 100px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.line-chart-header-balance {
  font-size: 14px;
  color: rgb(134, 141, 147);
  margin-right: 10px;
  font-weight: bold;
}

.clicked-line-chart-header-balance {
  margin-right: 10px;
  font-size: 14px;
  border-bottom: 2px solid black;
  color: #000;
  font-weight: bold;
}

.line-chart-header-holdings {
  font-size: 14px;
  color: rgb(134, 141, 147);
  font-weight: bold;
}

.clicked-line-chart-header-holdings {
  font-size: 14px;
  color: #000;
  border-bottom: 2px solid black;
  font-weight: bold;
}

div .card {
  border-radius: 20px;
}

.line-time-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  list-style: none;
  margin-bottom: 2px;
  cursor: pointer;
  align-items: flex-end;
  padding: 0px;
  font-size: 8pt;
  color: rgb(175, 177, 179);
}

.line-chart-headers {
  display: flex;
  flex-direction: row;
}

.line-toggle-item {
  width: fit-content;
  padding: 2px 5px 2px 5px;
}

.hovered {
  color: #000;
  font-weight: bold;
  background-color: rgb(214, 216, 216);
  padding: 2px 5px 2px 5px;
  border-radius: 10px;
}

.line-toggle-item:hover {
  color: #000;
  font-weight: bold;
  background-color: rgb(214, 216, 216);
  border-radius: 10px;
}

.linked-accounts-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 7px;
  cursor: pointer;
  height: 95vh;
  margin-top: 20px;
  margin-bottom: 10px;
  overflow-y: scroll;
}

.link-accounts-container {
  padding: 0px 20px 0px 20px;
  height: inherit;
}

.link-accounts-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.close-icon-link {
  color: rgb(175, 177, 180);
  font-size: 40px;
  font-weight: lighter;
}

.main-link-accounts-title {
  color: rgb(146, 148, 151);
  margin-left: 15px;
}

@media screen and (max-width: 450px) {
  .link-bank-logo {
    max-width: 120px;
  }
}

@media screen and (min-width: 451px) {
  .link-bank-logo {
    max-width: 160px;
  }
}

.link-bank-logo {
  min-width: 80px !important;
  max-height: 80px !important;
}
.link-bank-icon {
  min-width: 80px !important;
  max-width: 160px;
  max-height: 80px !important;
  height: inherit !important;
  font-size: x-large;
}
.link-bank-icon svg {
  width: 50%;
  height: 50%;
}

.account-grid-item {
  height: 150px;
  display: flex;
  border-bottom: 2px solid rgb(220, 222, 223);
}
/*.account-grid-item-quarter{
    height: 25%;
}*/
.account-grid-item:nth-child(odd) {
  border-right: 2px solid rgb(220, 222, 223);
}
.account-grid-item:nth-child(odd) {
  border-right: 2px solid rgb(220, 222, 223);
}
.account-grid-item:last-child {
  border-bottom: none;
}
.account-grid-item:nth-last-child(2):nth-child(odd) {
  border-bottom: none;
}

.link-accounts {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: inherit;
  width: 100%;
}

.plaid-tiles-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  border-top: 1px solid #000;
  width: 100%;
}

.plaid-link-search {
  background-color: rgb(232, 233, 234);
  border-radius: 20px;
  padding: 4px;
  padding-left: 20px;
  height: 20px;
  font-size: 10pt;
  color: rgb(120, 121, 125);
  border: none;
  width: 100%;
  margin-bottom: 10px;
}

.plaid-tile-asset-class {
  text-align: left;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 12pt;
}

.plaid-tile-header {
  height: fit-content;
}

.plaid-left-tiles-container {
  width: 33%;
  text-align: center;
  overflow-y: scroll;
  padding: 10px 5px 5px 0px;
  display: flex;
  flex-direction: column;
}

.plaid-middle-tiles-container {
  width: 33%;
  text-align: center;
  overflow-y: scroll;
  padding: 10px 5px 5px 5px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
}

.plaid-right-tiles-container {
  width: 33%;
  text-align: center;
  overflow-y: scroll;
  padding: 10px 0px 5px 5px;
  display: flex;
  flex-direction: column;
}

.plaid-left-tiles {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.plaid-left-tiles-left {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.plaid-left-tiles-left div {
  width: 100%;
  height: 50%;
  border-right: 1px solid rgb(220, 222, 223);
}

.plaid-left-tiles-right div {
  width: 100%;
  height: 50%;
}

.plaid-left-tiles-left .plaid-tile {
  border-bottom: 1px solid rgb(220, 222, 223);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

.plaid-left-tiles-right {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.plaid-left-tiles-right .plaid-tile {
  border-bottom: 1px solid rgb(220, 222, 223);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

.plaid-middle-tiles {
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
}

.plaid-mid-tiles-left {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.plaid-mid-tiles-left div {
  border-right: 1px solid rgb(220, 222, 223);
  width: 100%;
  height: 50%;
}

.plaid-mid-tiles-left .plaid-tile div {
  border-bottom: 1px solid rgb(220, 222, 223);
  border-top: none;
  background-color: #ffffff;
  border-left: none;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.plaid-mid-tiles-right {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.plaid-mid-tiles-right div {
  width: 100%;
  height: 50%;
}

.plaid-mid-tiles-right .plaid-tile div {
  border-bottom: 1px solid rgb(220, 222, 223);
  border-top: none;
  background-color: #ffffff;
  border-left: none;
  border-right: none;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.plaid-mid-top {
  text-align: center;
  align-items: center;
  overflow-y: scroll;
  height: 50%;
}

.plaid-mid-top-tiles {
  display: flex;
  justify-content: center;
}

.plaid-mid-bottom {
  margin-top: 5px;
  text-align: center;
  align-items: center;
  overflow-y: scroll;
  height: 40%;
}

.plaid-mid-bottom-tiles {
  display: flex;
  justify-content: center;
}

.plaid-right-tiles {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.plaid-right-top {
  text-align: center;
  align-items: center;
  /* overflow-y: scroll; */
  height: 50%;
}

.plaid-right-top-tiles {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.plaid-right-bottom {
  text-align: center;
  align-items: center;
  /* overflow-y: scroll; */
  height: 50%;
}

.plaid-right-bottom-tiles {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.plaid-link-disclaimer {
  font-size: 8pt;
  color: rgb(120, 121, 125);
  margin-bottom: 10px;
}

.plaid-tile {
  text-align: center;
  align-items: center;
  font-size: 12pt;
  cursor: pointer;
  width: 100%;
  height: 50%;
  margin-right: 0px;
}

.crypto-tile {
  text-align: center;
  align-items: center;
  font-size: 12pt;
  cursor: pointer;
  width: 100%;
  height: 50%;
  margin-right: 0px;
  background-color: red;
}

.net-cash-flow-tile-pos {
  background-color: rgb(30, 197, 119);
  height: 100px;
  border-radius: 9px;
  color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
}

.net-cash-flow-tile-neg {
  background-color: rgb(236, 27, 36);
  height: 100px;
  border-radius: 9px;
  color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
}

.net-cash-flow-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.net-cash-flow-header .dropdown {
  border: solid 1px #ffffff;
  background-color: rgb(30, 197, 119);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  width: 100px;
  margin-right: 5px;
}

.net-change-tag-pos {
  font-size: 12px;
  background-color: rgb(27, 136, 103);
  border-radius: 7px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 5px;
  font-weight: lighter;
  width: fit-content;
}

.net-change-tag-neg {
  font-size: 12px;
  background-color: rgb(153, 20, 40);
  border-radius: 7px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 5px;
  font-weight: lighter;
  width: fit-content;
}

.net-cash-header-left {
  margin-left: 10px;
}

.net-cash-header-right {
  margin-right: 5px;
}

.net-cash-flow-tile-body {
  margin-left: 10px;
  font-weight: bold;
}

.cash-flow-asset-tiles-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: 10px;
  margin-right: 10px;
  background-color: rgb(200, 223, 241);
}

.trnsctn-tile {
  font-size: 12px;
}

.trnsctn-details-container {
  border-bottom: solid 1px rgb(146, 148, 151);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  height: 35px;
  align-items: center;
}

.cash-flow-left-tiles {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.cash-flow-left-tiles-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-top: 10px;
  min-width: 20vw;
}

.cash-flow-middle-tiles-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.cash-flow-right-tiles-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.trnsctn-name {
  margin-left: 4px;
}

.trnsctn-account-balance {
  margin-right: 4px;
}

.bank-grid-tiles {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: inherit;
  border-radius: 9px;
  margin-bottom: 10px;
  width: 400px;
  height: 360px;
  cursor: pointer;
}

.bank-tile-msg-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.bank-tile-alert-text {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  color: #ffffff;
}

.alert-circle {
  background-color: rgb(236, 27, 36);
  display: flex;
  justify-content: center;
  text-align: center;
  width: 20px;
  height: 20px;
  padding-left: 4px;
  padding-right: 5px;
  padding-top: 4px;
  margin-right: 5px;
  border-radius: 50%;
}

.transaction-tile-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #ffffff;
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 55px;
  overflow-y: scroll;
  min-height: 800px;
  min-width: 4000px;
}

.manual-transaction-btn {
  color: rgb(0, 120, 245);
  padding-left: 15px;
  font-size: 12pt;
  cursor: pointer;
  width: fit-content;
}

.manual-transaction-open {
  width: 355px;
  height: 235px;
  border-radius: 9px;
  position: absolute;
}

.manual-transaction-btn:hover {
  color: rgb(14, 92, 178);
}

.popup {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 99999;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(146, 148, 151, 0.5);
}

.transaction-tile-list {
  background: rgb(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.transactions-popup-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

table .p-datatable-thead .name-column {
  background-color: #ffffff;
  font-size: 12px;
}

.name-column {
  font-size: 12px;
}

.p-sortable-column.name-column {
  font-size: 12px;
}

.columns {
  font-size: 12px;
}

td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.column-headers {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 5px;
  font-size: 10px;
}

.tile-transaction {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 12px;
  color: #000000;
  border-bottom: 1px solid rgb(146, 148, 151);
  padding-top: 10px;
  padding-bottom: 10px;
}

.tile-transaction-name {
  margin-right: 80px;
}

.tile-transaction-category {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  border-radius: 20px;
  background-color: rgb(229, 230, 231);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.right-columns {
  margin-right: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
}

.right-column-values {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 350px;
}

.tile-transaction-exclude {
  margin-right: 90px;
}

.tile-transaction-recurring {
  margin-right: 90px;
}

.tile-transaction-amount {
  margin-right: 90px;
}

.tile-transaction-date {
  margin-right: 90px;
}

.header-account-title {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.transaction-detail-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-weight: bold;
  height: fit-content;
}

.header-title-inst {
  text-align: start;
  width: fit-content;
}

.transaction-detail-ending {
  font-size: 8pt;
  color: rgb(157, 158, 161);
}

.transaction-detail-header-left {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  width: 325px;
}

.transaction-detail-header-left-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.transaction-detail-header-left-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.transaction-detail-header-middle {
  font-size: 18px;
}

.edit-transaction-detail-header-middle {
  font-size: 18px;
}

.edit-transaction-detail-header-middle:hover {
  font-size: 18px;
  color: grey;
  cursor: pointer;
}

.transaction-detail-header-logo {
  align-self: start;
  height: fit-content;
  width: 100px;
}

.transaction-detail-header-right {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 100%;
}

.header-right-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: end;
}

.transaction-tag-msg {
  display: flex;
  flex-direction: row;
}

.transaction-tag-text {
  font-size: 10px;
  color: #000000;
  margin-left: 5px;
  margin-bottom: 10px;
}

.transaction-tag-sign {
  color: #ffffff;
  background-color: rgb(236, 27, 36);
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 1px;
  font-size: 10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.transaction-search {
  background-color: rgb(229, 230, 231);
  color: rgb(157, 158, 161);
  border-radius: 20px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  overflow-x: visible;
}

.transaction-close {
  margin-left: 160px;
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: rgb(146, 148, 151);
}

.p-datatable-wrapper {
  display: flex;
  justify-content: center;
}

.table {
  color: #000000;
  font-size: 12px;
  border-bottom: 1px solid rgb(146, 148, 151);
  display: flex;
  justify-content: center;
}

.p-column-title {
  font-size: 10pt;
  font-weight: 300;
  font-family: 'Roboto';
  color: rgb(146, 148, 151);
}
.p-datatable .p-datatable-thead > tr > th {
  background-color: white;
}

.transactions-time-frame-dropdown {
  border: 1px solid rgb(146, 148, 151);
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 25px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  width: 160px;
}

.tile-transaction-left {
  display: flex;
  justify-content: center;
}

.tile-transaction-right {
  display: flex;
  justify-content: center;
}

.logo-container {
  width: fit-content;
  cursor: pointer;
}

.cash-flow-bank-logo {
  min-width: 30px !important;
  max-width: 120px;
  max-height: 30px !important;
  height: inherit !important;
}
.cash-flow-bank-icon {
  margin-left: 10px;
  min-width: 30px !important;
  max-width: 80px;
}
.holding-logo {
  width: 60px !important;
  height: 60px !important;
  object-fit: cover;
}

.bank-tile-account-name-outer-header {
  color: rgb(146, 148, 151);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 25px;
  padding-right: 10px;
}

@media screen and (max-width: 450px) {
  .bank-details-container {
    width: 325px;
    max-width: 98%;
  }
}

@media screen and (min-width: 451px) {
  .bank-details-container {
    width: 350px;
    max-width: 350px;
  }
}

.bank-details-container {
  height: 235px;
  border-radius: 9px;
  color: #ffffff;
  background-color: rgb(0, 0, 0, 0.5);
}

.bank-tile-header {
  margin-left: 20px;
  font-size: 14pt;
  font-weight: 400;
}

.bank-grid-tiles .p-dropdown-label.p-inputtext.p-placeholder {
  color: rgb(146, 148, 151);
}

.main-expenses-tile .p-dropdown-label.p-inputtext.p-placeholder {
  color: rgb(146, 148, 151);
}

.p-dropdown-items {
  width: 100%;
  font-size: 9pt;
  padding-left: 0px;
  /* overflow-y: scroll; */
}

.p-dropdown-item {
  padding-left: 0px;
  width: 100%;
}

.p-dropdown-label.p-inputtext {
  color: rgb(255, 255, 255);
  font-size: 12px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 50px;
}

.transaction-category .p-dropdown-label.p-inputtext {
  font-size: 16px;
  padding: 0.5rem 0.5rem;
}
.transaction-category .MuiOutlinedInput-inputMarginDense {
  padding-top: 8px;
  padding-bottom: 9px;
}

.main-expenses-tile .p-dropdown-label.p-inputtext {
  color: rgb(0, 0, 0);
}

tr {
  background-color: #ffffff;
}

#pr_id_1.p-dropdown.p-component.p-inputwrapper.p-inputwrapper {
  text-align: left;
}

.bank-details-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
}

.left-bank-details {
  margin-left: 15px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: medium;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-bank-details {
  margin-right: 15px;
  margin-top: 10px;
  font-size: 14px;
  max-width: 10%;
  overflow-x: hidden;
  white-space: nowrap;
}

.manual-bank-account-balance:hover {
  color: black;
  cursor: pointer;
}

.tile-balance-details {
  margin-left: 15px;
}

.manual-tile-balance-details {
  margin-left: 15px;
}

.manual-tile-balance-details:hover {
  color: #000;
  cursor: pointer;
}

.tile-balance-title {
  font-size: 12px;
  margin-bottom: 10px;
}

.p-dropdown-panel.p-component.p-connected-overlay-enter-done {
  width: fit-content;
  height: fit-content;
}

.transactions-popup-list .p-dropdown-items-wrapper {
  min-height: 300px;
}

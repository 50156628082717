.expenses-tile {
  background-color: rgb(249, 79, 88);
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;
  border-radius: 9px;
  padding: 15px;
}

.expenses-tile-expenses {
  margin-top: 5px;
}

.expenses-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  border-bottom: 1px solid #ffffff;
}

.expenses-tile .p-dropdown-label.p-inputtext.p-placeholder {
  color: #ffffff;
}

.expenses-tile .p-dropdown-trigger {
  color: #ffffff;
}

.expenses-header-left {
  margin-left: 10px;
}

.expenses-header-right {
  margin-right: 5px;
}

.expenses-tile-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.expenses-tile-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 38px;
  text-align: center;
  margin-top: 5px;
}

.expense-item-label {
  font-size: 10pt;
  font-weight: 400;
}

.expense-item-value {
  font-size: 12pt;
  font-weight: bold;
}

.expense-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.toggle-icons {
  font-size: 25px;
  cursor: pointer;
}

.MuiMobileStepper-dots {
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.MuiMobileStepper-dot {
  min-width: 10px;
  min-height: 10px;
}

.MuiMobileStepper-dotActive {
  background-color: rgb(0, 120, 245) !important;
}

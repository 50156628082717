.log-in-container {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
}

.log-in-wrapper {
  width: 100%;
  height: 100%;
  padding: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  footer {
    margin-top: '100px';
  }
}

.log-in-left {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.log-in-form-input-box {
  height: 40px;
  width: 99%;
  font-size: 15px;
  border-radius: 5px;
  border-top: 1px solid rgb(214, 216, 216);
  border-bottom: 1px solid rgb(214, 216, 216);
  border-left: 1px solid rgb(214, 216, 216);
  border-right: 1px solid rgb(214, 216, 216);
}

.log-in-page-msg {
  /* font-size: 25pt; */
  /* font-weight: 600; */
  color: rgb(0, 120, 245);
  /* margin-bottom: 40px; */
  /* top: 0; */
  display: flex;
  justify-content: flex-start;
}

.log-in-form-content {
  width: 70%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (max-device-width: 450px){
  .log-in-form-content {
    width: 90%;
  }
  .log-in-wrapper {
    padding: 4px;
  }
}

.log-in-form {
  background-color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  padding-top: 0px;
  border-radius: 3px;
  width: 100%;
}

.log-in-forgot-password-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.log-in-forgot-password {
  color: #000;
  font-weight: 600;
  font-size: 10pt;
  padding-bottom: 1px;
  cursor: pointer;
  border-bottom: 1px solid #000;
}

.log-in-form-input-box {
  padding: 5px 10px;
  margin: 5px 0px;
}

.log-in-form-input-btn {
  margin-top: 25px;
  margin-bottom: 5px;
  color: #ffffff;
  background-color: rgb(0, 120, 245);
  width: 100%;
  border: none;
  height: 40px;
  border-radius: 5px;
  font-size: 10pt;
  font-weight: bold;
  cursor: pointer;
}
.log-in-form-input-btn div {
  display: flex;
  align-items: center;
}
.log-in-form-input-btn div span {
  width: 100%;
  margin-left: -15px;
}

.log-in-form-input-btn svg {
  margin-left:5px;
}

.log-in-form-input-btn.sso {
  color: rgb(0, 120, 245);
  background-color: #ffffff;
  border: solid 1px rgb(0, 120, 245);
  margin: 5px;
}

.log-in-msg {
  padding-top:20px;
}

.log-in-msg-button {
  text-decoration: none;
  text-align: center;
  color: #000000;
}

.log-in-create-account-msg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top:20px;
}

.log-in-create-account {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.log-in-sign-up-msg {
  font-size: 10pt;
  font-weight: 400;
  margin-right: 4px;
}

.log-in-error-msg {
  display: flex;
  position: relative;
  color: red;
  margin-top: 5px;
}

.log-in-msg-button {
  font-size: 10pt;
  font-weight: 600;
  border-bottom: 1px solid #000;
  cursor: pointer;
  padding-bottom: 1px;
}

.log-in-msg-button:hover {
  border-bottom: 1px solid rgb(0, 120, 245);
}

.log-in-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
  width: 50%;
}

.log-in-logo-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.log-in-logo {
  max-width: 80%;
  min-width: 80%;
  max-height: 100%;
  min-height: 100%;
}

.logo-text {
  font-size: 35pt;
  color: #ffffff;
  font-weight: bold;
  line-height: 40px;
  top: 40%;
  width: fit-content;
}

.logo-footer {
  font-size: 15pt;
  position: absolute;
  color: #ffffff;
  width: fit-content;
  font-weight: bold;
  bottom: 25%;
}

.logo-header {
  width: fit-content;
  height: fit-content;
  margin-bottom: -25px;
}

.logo-text-container {
  font-size: 35pt;
  position: absolute;
  color: #ffffff;
  font-weight: bold;
  line-height: 40px;
  top: 40%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

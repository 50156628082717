.msg-styles {
  font-size: 14pt;
  color: rgb(136, 137, 140);
}

.msg-reminder {
  font-size: 12pt;
  color: rgb(136, 137, 140);
  max-width: 560px;
}

.success-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.success-left {
  display: flex;
  flex-direction: column;
}

.success-main-msg {
  display: flex;
  flex-direction: row;
}

.connect-header {
  margin-bottom: 0px;
}

.success-list {
  padding-left: 20px;
  margin-top: 8px;
  color: rgb(136, 137, 140);
}

.small-check {
  width: 30px;
  height: 30px;
  color: rgb(4, 205, 125);
}
@media screen and (max-width: 400px) {
  .small-check {
    width: 20px;
    height: 20px;
  }
}

.big-check {
  color: rgb(4, 205, 125);
  width: 300px;
  height: 300px;
}

.bolded-info {
  font-weight: 800;
  text-transform: capitalize;
}

.logout-btn {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.logout-btn:hover {
  color: rgb(19, 194, 230);
}

* {
  text-decoration: none;
}

.liquid-tile {
  text-align: center;
  color: #ffffff;
  background-color: rgb(30, 197, 119);
  border-radius: 9px;
  min-height: 395px;
  max-height: 395px;
}

.insights-tile {
  color: #ffffff;
  background-color: rgb(46, 54, 201);
  border-radius: 9px;
  height: 235px;
  padding: 20px;
  overflow: hidden;
}

.insights-tile .p-dropdown-label.p-inputtext.p-placeholder {
  color: #ffffff;
}

.insights-tile .p-dropdown-trigger {
  color: #ffffff;
}

.insights-tile-body {
  height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  font-weight: bold;
  padding-bottom: 20px;
}

.insights-tile-title {
  text-align: left;
  font-size: 14pt;
  font-weight: bold;
  color: #fff;
}

.insights-articles {
  color: #ffffff;
  text-decoration: #ffffff;
  font-size: 10pt;
  padding: 10px;
  border-bottom: 1px solid rgb(164, 165, 225);
}

.last-article {
  color: #ffffff;
  text-decoration: #ffffff;
  font-size: 10pt;
  padding: 10px;
}

.insights-article {
  color: #ffffff;
  margin-left: 10px;
}

.insights-tile-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.article-content img {
  max-width: 100%;
}

.alternative-tile {
  color: #ffffff;
  background-color: rgb(0, 120, 245);
  border-radius: 9px;
  min-height: 270px;
  max-height: 270px;
}

.personal-tile {
  color: #ffffff;
  background-color: rgb(0, 165, 163);
  border-radius: 9px;
  min-height: 270px;
  max-height: 270px;
}

.expense-tracker-tile {
  color: #ffffff;
  background-color: rgb(249, 79, 89);
  border-radius: 9px;
  min-height: 59px;
  max-height: 60px;
  text-decoration: none;
}

@media screen and (min-width: 996px) {
  .liquid-tile {
    /* width: 35vw; */
    height: 57vh;
    max-width: 481px;
  }
}

@media screen and (min-width: 996px) {
  .alternative-tile {
    height: 40.5vh;
    /* width: 33vw; */
    max-width: 447px;
  }
}

@media screen and (min-width: 996px) {
  .personal-tile {
    /* width: 33vw; */
    max-width: 447px;
  }
}

@media screen and (max-width: 995px) {
  .liquid-tile {
    /* width: 90vw; */
    margin-bottom: 1vh;
    margin-right: 5px;
  }
}

@media screen and (max-width: 995px) {
  .alternative-tile {
    /* width: 90vw; */
    margin-bottom: 1vh;
    height: 30vw;
    margin-right: 5px;
  }
}

@media screen and (max-width: 995px) {
  .personal-tile {
    /* width: 90vw; */
    margin-bottom: 7px;
    height: 30vw;
    margin-right: 5px;
  }
}

.liquid-doughnut {
  height: 215px;
}

.asset-totals-container {
  border-radius: 9px;
}

.asset-totals-container .liquid-asset-doughnut-and-keys {
  display: flex;
  justify-content: space-between;
  width: 95%;
}

.liquid-doughnut-keys {
  font-size: 140px;
  fill: white;
  font-weight: 225;
  width: 380px;
}

.asset-totals-container .liquid-doughnut-key-svg {
  display: flex;
  justify-content: center;
  width: 225px;
}

.liquid-doughnut-key-svg {
  width: 105%;
}

.insights-text ul {
  padding: 0%;
}

.insights-text ul p {
  font-size: 12px;
  overflow: hidden;
  font-weight: bold;
}

.right-doughnut {
  display: flex;
  justify-content: space-around;
  margin-left: 90px;
}

.right-doughnut .alternative-doughnut {
  margin-left: 10px;
  margin-top: 10px;
}

.right-doughnut .personal-doughnut {
  margin-left: 10px;
  margin-top: 10px;
}

.right-doughnut-keys-svg {
  font-size: 140px;
  fill: white;
  font-weight: 225;
}

.tile-title {
  font-size: 18px;
  text-align: center;
  font-weight: medium;
  padding-top: 20px;
  margin-top: 0%;
  margin-bottom: 5px;
}

.tile-title p {
  margin-top: 5px;
}

.liquid-tile .doughnut-total {
  font-size: 18px;
  font-weight: bold;
}

.alternative-tile .doughnut-total {
  font-size: 18px;
  font-weight: bold;
}

.personal-tile .doughnut-total {
  font-size: 18px;
  font-weight: bold;
}

.react-grid-item.react-draggable {
  cursor: move;
}

.react-grid-item.react-draggable-dragging {
  cursor: grabbing;
}

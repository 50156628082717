.sign-up-wrapper {
  width: 100%;
  padding: 0;
  padding-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sign-up-wrapper footer {
  margin-top: 100px;
}

@media only screen and (max-device-width: 450px){
  .sign-up-wrapper {
    padding-top: 20px;
  }
}

.sign-up-sub-header {
  width: 100%;
  padding: 0px 5px;
  font-size: 14pt;
  display: flex;
  justify-content: flex-start;
}

.sign-up-logo-container {
  display: flex;
  justify-content: flex-start;
  width: 500px;
  margin-left:20%;
  margin-bottom: 56px;
}
@media only screen and (max-width: 960px){
  .sign-up-logo-container {
    margin-left:0px;
    width: 70%;
  }
}
@media only screen and (max-width: 450px){
  .sign-up-logo-container {
    margin-left:0px;
    width: 90%;
  }
}

.sign-up-left {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.sign-up-form-label {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  color: rgb(120, 121, 125);
  margin-bottom: 5px;
  margin-left: 2px;
}

.sign-up-form-input-box {
  height: 40px;
  width: 99%;
  padding: 5px 10px;
  margin: 5px 0px;
  font-size: 15px;
  border-radius: 5px;
  border-top: 1px solid rgb(214, 216, 216);
  border-bottom: 1px solid rgb(214, 216, 216);
  border-left: 1px solid rgb(214, 216, 216);
  border-right: 1px solid rgb(214, 216, 216);
}

.sign-up-form-input-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: rgb(0, 120, 245);
  width: 100%;
  border: none;
  height: 40px;
  border-radius: 5px;
  font-size: 10pt;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sign-up-page-msg {
  font-size: 25pt;
  font-weight: 600;
  color: rgb(0, 120, 245);
  margin-bottom: 40px;
  top: 0;
  display: flex;
  justify-content: flex-start;
}

.sign-up-form-content {
  width: 450px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(120, 121, 125, 0.3) 0px 2px 8px 0px;
  border-radius: 6px;
  padding: 16px;
  margin-right:10%;
  margin-left:20%;
}
@media only screen and (max-width: 960px){
  .sign-up-form-content {
    width: 70%;
    min-width: 450px;
    margin: 0px;
  }
}
@media only screen and (max-width: 450px){
  .sign-up-form-content {
    width: 90%;
    min-width: 0px;
    margin: 0px;
  }
}

.sign-up-form {
  background-color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  padding-top: 0px;
  border-radius: 3px;
  width: 100%;
}

.sign-up-error-msg {
  display: flex;
  position: relative;
  color: red;
  margin-bottom: 5px;
  margin-top: 5px;
}

.sign-up-form-input {
  margin-top: 1px;
}

.log-in-msg {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.log-in-msg-text {
  font-size: 10pt;
  font-weight: 400;
  margin-right: 4px;
}

.log-in-msg-button {
  text-decoration: none;
  text-align: center;
  color: #000000;
}

.go-to-log-in {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.sign-up-msg-button {
  font-size: 10pt;
  font-weight: 600;
  border-bottom: 1px solid #000;
  cursor: pointer;
  padding-bottom: 1px;
}

.sign-up-msg-button:hover {
  border-bottom: 1px solid rgb(0, 120, 245);
}

.sign-up-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 24px;
  height: 100%;
  width: 50%;
  border-radius: 3px;
  padding: 3%;
  padding-top: 56px;
  color: rgb(0, 120, 245);
}

.MuiListItemText-primary {
  font-size: 20px !important;
  padding-bottom: 4px;
}

.main-expenses-tile {
  background-color: white;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 10px;
  min-height: fit-content;
  width: 100%;
  max-width: 481px;
  padding: 10px 15px 10px 15px;
}

.expense-tile-sublist-count {
  color: rgb(146, 148, 151);
}

.positive-net-cash-flow-tag {
  border-radius: 20px;
  background-color: rgb(29, 197, 119);
  color: #fff;
  font-weight: bold;
  text-align: left;
  font-size: 10pt;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  width: 120px;
}

.negative-net-cash-flow-tag {
  border-radius: 20px;
  background-color: rgb(249, 79, 88);
  color: #fff;
  font-weight: bold;
  text-align: left;
  font-size: 10pt;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  width: 120px;
}

.expenses-tile-expenses {
  margin-top: 15px;
}

.expenses-tile-income-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  font-size: 10pt;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid rgb(241, 242, 242);
  cursor: pointer;
}

.income-tag {
  color: #ffffff;
  text-align: left;
  font-size: 10pt;
  border-radius: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  width: 120px;
  background-color: rgb(29, 197, 119);
}

.expense-tile-body-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 140px;
}

.expense-tile-total,
.expense-tile-category-name,
.expense-tile-body-left-sub {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.expense-tile-category-name {
  max-width: 220px;
  display: block;
}

.expense-tile-body-left-sub {
  margin-left: 20px;
  max-width: 200px;
}

.expense-tag {
  color: #ffffff;
  text-align: left;
  font-size: 10pt;
  border-radius: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  width: 120px;
  background-color: rgb(249, 79, 88);
}

.expense-tag-msg {
  color: rgb(146, 148, 151);
  font-size: 7pt;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.category-transaction-list-title {
  display: flex;
  justify-content: center;
  text-transform: capitalize;
}

.add-accounts-tile-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  min-height: 325px;
  max-height: 375px;
  background-color: #ffffff;
}

.add-accounts-tile-body {
  color: rgb(146, 148, 151);
}

.add-accounts-tile-header {
  font-weight: bold;
  font-size: 16pt;
  color: rgb(146, 148, 151);
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}

.add-accounts-tile-footer {
  font-weight: bold;
  font-size: 10pt;
  margin: 0px 0px 20px 0px;
  display: flex;
  justify-content: center;
}

.add-accounts-tile-symbols {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 100px;
  margin-bottom: 20px;
}

.phone-icon {
  font-size: 90px;
}

.phone-icon-container {
  z-index: 2;
  height: 70px;
  width: 65px;
  background-size: contain;
  background-color: white;
  margin-right: 150px;
  margin-bottom: 330px;
}

.dashed-circle {
  color: rgb(4, 205, 125);
  height: 115px;
  width: 115px;
  z-index: 1;
  position: absolute;
  text-align: center;
  align-content: center;
}

.circle-plus {
  color: rgb(34, 129, 251);
  font-size: 60px;
  font-weight: bolder;
  display: block;
  position: absolute;
  z-index: 2;
  margin-top: 7px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.square-icon {
  color: #ffffff;
  position: absolute;
  background-color: rgb(34, 129, 251);
  height: 55px;
  width: 55px;
  z-index: 2;
  border-radius: 7px;
  margin-top: 60px;
  margin-left: 115px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.square-icon-text {
  font-size: 50px;
}

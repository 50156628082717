.nav-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.asset-totals-container {
  background-color: #fff;
  margin-bottom: 20px;
}

.liquid-asset-tiles-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: 65px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: rgb(200, 223, 241);
}

.alternative-asset-tiles-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: 65px;
  margin-right: 10px;
  margin-top: 10px;
}

.liquid-left-tiles {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.alt-left-tiles {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.liquid-right-side-tiles {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.alt-right-side-tiles {
  font-size: 18px;
  display: flex;
  text-align: center;
  flex-direction: column;
  border-radius: 8px;
  width: 400px;
  margin-bottom: 10px;
}

.main-asset-grid-tile {
  background-color: white;
  font-size: 18px;
  display: flex;
  text-align: center;
  flex-direction: column;
  border-radius: 8px;
  height: 610px;
  width: 400px;
}

.alt-left-tiles .main-asset-grid-tile {
  padding: 20px;
}

h1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.asset-title {
  /* font-size: 16px; */
  /* font-weight: normal; */
  color: rgb(36, 99, 92);
  margin-top: 10px;
  margin-bottom: 0px;
}
.change-in-value {
  margin: 5px 0px;
}

.layout {
  align-items: flex-start;
  color: black;
  height: 100vh;
  margin-left: 6%;
  margin-top: 5px;
  text-align: center;
}

.grid-tiles {
  background-color: white;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  border-radius: 8px;
  height: 400px;
  width: 400px;
  margin-bottom: 10px;
}

.liquid-asset-doughnut {
  flex-shrink: 1;
  min-width: 0;
}

.liquid-asset-doughnut-and-keys {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.liquid-asset-doughnut-keys {
  color: black;
  font-size: 120px;
  font-weight: thin;
  height: 150px;
}

.alternative-asset-doughnut {
  background-color: rgb(255, 255, 255);
  height: 25px;
  width: 25px;
}

.alternative-asset-doughnut-and-keys {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.alternative-asset-doughnut-keys {
  color: black;
  font-size: 120px;
  font-weight: thin;
  height: 150px;
}

.alternative-doughnut-key-svg {
  width: 310px;
}

.alt-asset-doughnut-keys {
  height: 5px;
}

.personal-asset-doughnut {
  height: 25px;
  width: 25px;
}

.personal-asset-doughnut-and-keys {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.personal-asset-doughnut-keys {
  color: black;
  font-size: 120px;
  font-weight: thin;
  height: 150px;
}

.grid-asset {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.asset-accounts {
  font-size: 14px;
  color: #000000;
}

.donut-charts {
  height: 600px;
  width: 400px;
  text-align: center;
}

canvas {
  display: flex;
  justify-content: center;
  width: 200px;
}

.total {
  font-size: 18px;
  color: rgb(37, 100, 93);
}

.hide-total {
  opacity: 0;
}

.total-text {
  font-size: 12px;
  color: #000000;
}

.canvas-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  background-color: rgba(255, 255, 255, 0);
}

.inner-wrapper {
  display: flex;
  justify-content: center;
  width: 180px;
  height: 180px;
  border-radius: 110px;
}

.inner-circle-text {
  position: absolute;
  font-weight: bold;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.alternative-asset-doughnut-key-svg {
  font-size: 125px;
}

.alternative-asset-doughnut-keys-svg {
  font-size: 125px;
  width: 400px;
}

.asset-detail-popup {
  z-index: 2;
  display: flex;
  justify-content: center;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(146, 148, 151, 0.5);
}

.popup-container {
}

.insights-page-articles {
  background-color: #fff;
  padding: 10px;
  text-decoration: none;
}

.insights-page-article-list {
  width: 100%;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(175, 175, 175);
}

.insights-page-article-item {
  display: flex;
  flex-direction: column;
}

.insights-page-article-item-title {
  color: #000;
}

.insights-page-article-item-blurb {
  color: rgb(120, 121, 124);
}

.insights-page-msg {
  color: rgb(104, 106, 109);
  font-weight: 600;
  font-size: 12pt;
}

.insights-liq-color {
  background-color: rgb(5, 166, 96);
}

.insights-alt-color {
  background-color: rgb(21, 101, 187);
}

.insights-pers-color {
  background-color: rgb(0, 143, 136);
}

.insights-exp-color {
  background-color: rgb(53, 124, 83);
}

.insights-lia-color {
  background-color: rgb(180, 75, 82);
}

.insights-view-tag {
  color: #fff;
  font-size: 1rem;
  border-radius: 20px;
  width: fit-content;
  padding: 2px 20px 2px 20px;
  margin-right: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
  line-height: 100%;
  display: inline-block;
  text-transform: capitalize;
}

.insights-page-tag {
  color: #fff;
  width: fit-content;
  font-weight: 400;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 2px 20px 2px 20px;
  white-space: nowrap;
}

.insights-page-tags {
  display: flex;
  flex-wrap: wrap;
}

.insights-tag-dot {
  height: 0.65em;
  width: 0.65em;
  border-radius: 50%;
  display: inline-block;
}

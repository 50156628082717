.MuiOutlinedInput-inputMarginDense {
  padding-top: 8px !important;
  padding-bottom: 9px !important;
}
.MuiFormControl-marginDense {
  margin-top: 0 !important;
}
.MuiInputBase-input {
  color: #6c757d !important;
}

.asset-page-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.tile-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media screen and (max-width: 995px) {
  .tile-container {
    flex-wrap: wrap;
  }
}

.left-tiles-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.networth-info-container {
  margin-top: 5px;
}
/*.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
    margin: 0;
    width: 100%;
}*/

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-4 {
  width: 100%;
  margin: 0;
}

.mid-tiles-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.right-tiles-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.vault-link {
  font-size: 18px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 8px;
  text-align: center;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 69px;
  max-height: 69px;
  margin-bottom: 8px;
}

.liquid-tile {
  margin-bottom: 8px;
}

.insights-tile {
  margin-bottom: 8px;
}

.alternative-tile {
  margin-bottom: 8px;
}

.personal-tile {
  margin-bottom: 8px;
}

.expense-tracker-tile {
  margin-bottom: 8px;
}

.verify-email-popup-container {
  display: flex;
  justify-content: center;
  position: fixed;
  text-align: center;
  align-items: center;
  z-index: 9999999;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(146, 148, 151, 0.6);
}

.hide-verification {
  display: none;
}

.verify-email-popup {
  height: 450px;
  width: 450px;
  background-color: #ffffff;
  color: rgb(0, 120, 245);
  border-radius: 3px;
  padding: 35px;
  top: 50%;
}

.verify-email-body {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.verify-email-header {
  font-weight: 600;
  font-size: 20pt;
}

.verify-email-icon {
  font-size: 125pt;
}

.verify-email-mid {
  font-size: 16pt;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 25px;
}

.confirm-email-button {
  color: #ffffff;
  border: none;
  background-color: rgb(0, 120, 245);
  border-radius: 5px;
  height: 50px;
  width: 80%;
  cursor: pointer;
}

.verify-email-footer {
  color: #000;
  font-size: 12pt;
  margin-top: 10px;
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.verify-email-footer:hover {
  color: rgb(0, 120, 245);
  border-bottom: 1px solid rgb(0, 120, 245);
}
